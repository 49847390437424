* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%; /*для айоса, чтобы размер шрифта не менялся после поворота экрана*/
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  font-family: 'Manrope', sans-serif;
}

.amo-livechat > iframe #bubble {
  display: none !important;
}

body {
  min-height: 100vh;
}
body.hide-amo .amo-button-holder {
  display: none;
}

a {
  color: black;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
}

img {
  color: transparent;
}

ul {
  list-style-type: none;
}

input,
textarea,
button,
select,
div,
a {
  font-family: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

.ytp-pause-overlay {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.semibold {
  font-weight: 600;
}

/* intl-tel-input library */

.iti__country-list {
  border-radius: 10px;
}
.iti--container {
  z-index: 100001 !important;
}
.iti__country {
  font-size: 14px;
  font-weight: 400;
}

.iti__country-name {
  color: #686868;
}

.iti__flag {
  background-image: url('https://medpoint.pro/static/images/flags@2x.png') !important;
  background-size: 5652px 15px !important;
}

.iti__flag-container {
  display: flex;
  align-items: center;
}

.iti__selected-flag {
  height: 60% !important;
  padding-right: 14px !important;
  border-right: 1px #e5e3e3 solid;
}
.iti--allow-dropdown {
  width: 100%;
}
.iti--allow-dropdown input {
  padding-left: 69px !important;
}
