:root {
  /* fonts */
  --property-title-1: normal 600 60px / 82px 'Manrope', sans-serif;
  --property-title-2: normal 600 40px / 56px 'Manrope', sans-serif;
  --property-title-3: normal 600 32px / 45px 'Manrope', sans-serif;
  --property-title-4: normal 600 28px / 39px 'Manrope', sans-serif;
  --property-title-5: normal 600 22px / 31px 'Manrope', sans-serif;

  --property-text-1: normal 400 18px / 25px 'Manrope', sans-serif;
  --property-text-2: normal 400 16px / 22px 'Manrope', sans-serif;
  --property-text-3: normal 400 14px / 20px 'Manrope', sans-serif;
  --property-text-4: normal 400 12px / 17px 'Manrope', sans-serif;

  /* other no-design */
  --property-container-size: 1110px;
  --property-border-radius: 10px;

  --property-h1-margin: 0 0 25px;
  --property-h1-margin-mobile: 0 15px 20px;
  --property-switch-toggle-margin: 0 0 40px;
  --property-switch-toggle-margin-mobile: 0 15px 20px;
  --property-card-margin: 30px;
  --property-card-margin-mobile: 20px;

  --property-h1-size: 34px;
  --property-h1-size-mobile: 24px;
  --property-h1-weight: 500;
  --property-main-layout-text-size: 14px;

  --property-pc-side-padding: 20px;
  --property-mobile-side-padding: 15px;

  /* Для синхронизации отступа на будущем вебинаре у правого блока и левого */
  --property-webinar-h2-line-height: 34px;

  /* Высота основого навбара без полосок и прочего */
  --property-navbar-height: 68px;


  /* Начало для Дизайн системы 2025 года*/

  --border-radius-main: 20px;
}