:root {
  /* primary */
  --color-primary-100: #19b5ff;
  --color-primary-80: #47c4ff;
  --color-primary-60: #75d3ff;
  --color-primary-40: #bae9ff;
  --color-primary-20: #e8f8ff;
  --color-primary-white: #ffffff;

  /* base */
  --color-base-black: #000000;
  --color-base-95: #4a4a4a;
  --color-base-80: #686868;
  --color-base-60: #9f9f9f;
  --color-base-40: #dedede;
  --color-base-20: #ebebeb;
  --color-base-00: #fdfdfd;

  /* lavender */
  --color-lavender-100: #727fff;
  --color-lavender-80: #828dff;
  --color-lavender-60: #a1aaff;
  --color-lavender-40: #c9ceff;
  --color-lavender-20: #dcdfff;
  --color-lavender-00: #e9ebff;

  /* error */
  --color-error-100: #f44747;
  --color-error-80: #f55959;
  --color-error-60: #f77e7e;
  --color-error-40: #faa3a3;
  --color-error-20: #fcc8c8;
  --color-error-00: #feeded;

  /* success */
  --color-success-100: #229f6b;
  --color-success-80: #28b97d;
  --color-success-60: #48d89c;
  --color-success-40: #7ce3b8;
  --color-success-20: #b1eed5;
  --color-success-00: #e7f6ef;

  /* extra */
  --color-orange: #fc8f00;
  --color-yellow: #ffcb46;
  --color-burning-orange: #ff7a36;
  --color-linen: #fbebe8;
  --color-sauvignon: #fff6f3;
  --color-blue: #2d5afa;
  --color-titan-white: #e9ebff;
  --color-pink: #ff6f9a;
  --color-tutu: #fff0f8;
  --color-purple: #c65ee5;
  --color-blue-chalk: #f7e8ff;

  /* students */
  --color-students-primary: #5878f6;
  --color-students-yellow: #ffce00;
  --color-students-green: #74ca54;

  /* game */
  --color-card-pink: #ffdef9;
  --color-card-lavanda: #e2eafd;
  --color-card-beige: #ffe9e8;
  --color-card-grey: #e2eafd;
  --color-card-papaya: #fff4d6;
  --color-card-biscuit: #ffe5c3;

  /* other - no design */
  --color-secondary-main: #56b5ff;
  --color-blue-border: #16bdff;
  --color-blue-border-2: #d2e5f3;
  --color-filter-text: #8fb9cc;
  --color-bright-blue: #2d7ffa;
  --color-bright-blue-2: #577dff;
  --color-bright-blue-bg: #e8edff;
  --color-grey-bg: #EFEFEF;
  --color-grey-ultra-light: #eeeeee;
  --color-grey-ultra-light-2: #e1e1e1;
  --color-grey-2: #e4e7ec;
  --color-grey-dark-2: #636363;
  --color-grey-dark-3: #515155;
  --color-grey-dark-4: #747474;
  --color-grey-dark-disabled: #6363634d;
  --color-grey-border-2: #d8e3ee;
  --color-green: #8ab861;
  --color-green-bg: #e9ffd5;
  --color-orange-bg: #fff4e6;
  --color-orange-bg-2: #fbd391;

  /* intro-alt */
  --color-sand: #ffcf87;
  --intro-alt-bg: #f8f8f8;
}