@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes confetti_animation {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(100%);
  }
}
