/* Manrope */

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src:
    url('../fonts/Manrope-ExtraLight.woff2') format('woff2'),
    url('../fonts/Manrope-ExtraLight.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/Manrope-Light.woff2') format('woff2'),
    url('../fonts/Manrope-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Manrope-Regular.woff2') format('woff2'),
    url('../fonts/Manrope-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Manrope-Medium.woff2') format('woff2'),
    url('../fonts/Manrope-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src:
    url('../fonts/Manrope-SemiBold.woff2') format('woff2'),
    url('../fonts/Manrope-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Manrope-Bold.woff2') format('woff2'),
    url('../fonts/Manrope-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src:
    url('../fonts/Manrope-ExtraBold.woff2') format('woff2'),
    url('../fonts/Manrope-ExtraBold.woff') format('woff');
}

/* dotsfont */

@font-face {
  font-display: swap;
  font-family: 'dotsfont';
  src:
    url('../fonts/dotsfont.woff2') format('woff2'),
    url('../fonts/dotsfont.woff') format('woff');
}

/* Roboto */

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src:
    url('../fonts/Roboto-Thin.woff2') format('woff2'),
    url('../fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/Roboto-Light.woff2') format('woff2'),
    url('../fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src:
    url('../fonts/Roboto-Black.woff2') format('woff2'),
    url('../fonts/Roboto-Black.woff') format('woff');
}

/* Lora */

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Lora-Regular.woff2') format('woff2'),
    url('../fonts/Lora-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src:
    url('../fonts/Lora-Italic.woff2') format('woff2'),
    url('../fonts/Lora-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Lora-Medium.woff2') format('woff2'),
    url('../fonts/Lora-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  src:
    url('../fonts/Lora-MediumItalic.woff2') format('woff2'),
    url('../fonts/Lora-MediumItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  src:
    url('../fonts/Lora-SemiBold.woff2') format('woff2'),
    url('../fonts/Lora-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  src:
    url('../fonts/Lora-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Lora-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Lora-Bold.woff2') format('woff2'),
    url('../fonts/Lora-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  src:
    url('../fonts/Lora-BoldItalic.woff2') format('woff2'),
    url('../fonts/Lora-BoldItalic.woff') format('woff');
}

/* Exo 2 */

@font-face {
  font-display: swap;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Exo2-Regular.woff2') format('woff2'),
    url('../fonts/Exo2-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Exo2-Medium.woff2') format('woff2'),
    url('../fonts/Exo2-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 600;
  src:
    url('../fonts/Exo2-SemiBold.woff2') format('woff2'),
    url('../fonts/Exo2-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Exo2-Bold.woff2') format('woff2'),
    url('../fonts/Exo2-Bold.woff') format('woff');
}

/* Inter */

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Russisch Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/RussischSans-Italic.ttf') format('ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Prosto One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ProstoOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/PlayfairDisplaySC-Italic.woff2') format('woff2'),
    url('../fonts/PlayfairDisplaySC-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Russisch Sans';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/RussischSans-Regular.woff2') format('woff2'),
    url('../fonts/RussischSans-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Russisch Sans';
  font-style: italic;
  font-weight: 600;
  src:
    url('../fonts/RussischSans-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/RussischSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Russisch Sans';
  font-style: italic;
  font-weight: 400;
  src:
    url('../fonts/RussischSans-Italic.woff2') format('woff2'),
    url('../fonts/RussischSans-Italic.woff') format('woff');
}

/* Caveat */

@font-face {
  font-display: swap;
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Caveat-Regular.woff2') format('woff2'),
    url('../fonts/Caveat-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Caveat-Bold.woff2') format('woff2'),
    url('../fonts/Caveat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/Magistral-Light.woff2') format('woff2'),
    url('../fonts/Magistral-Light.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/Magistral-BookItalic.woff2') format('woff2'),
    url('../fonts/Magistral-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/Magistral-Book.woff2') format('woff2'),
    url('../fonts/Magistral-Book.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Magistral-Medium.woff2') format('woff2'),
    url('../fonts/Magistral-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: bold;
  src:
    url('../fonts/Magistral-Bold.woff2') format('woff2'),
    url('../fonts/Magistral-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Magistral';
  font-style: normal;
  font-weight: 800;
  src:
    url('../fonts/Magistral-ExtraBold.woff2') format('woff2'),
    url('../fonts/Magistral-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/Golos Text_Regular.woff2') format('woff2'),
    url('../fonts/Golos Text_Regular.woff') format('woff');
}

@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Golos Text_Medium.woff2') format('woff2'),
    url('../fonts/Golos Text_Medium.woff') format('woff');
}

/* TildaSans */

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/TildaSans-Regular.woff2') format('woff2'),
    url('../fonts/TildaSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/TildaSans-Medium.woff2') format('woff2'),
    url('../fonts/TildaSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/TildaSans-Bold.woff2') format('woff2'),
    url('../fonts/TildaSans-Bold.woff') format('woff');
}
